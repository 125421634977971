<template>
    <b-modal :ref="modalRef" hide-header hide-footer hide-backdrop centered @hidden="onHidden">
        <b-col class="text-center" sm="10" offset-sm="1">
            <h1>Félicitations !</h1>
            <p>Vous avez attrapé le slime suivant :</p>
            <div class="slime">
                <b-img :src="requireSlimeImgSrc(slime)" :alt="getSlimeNom(slime)" class="collectable-item-img"/>
                <h3>{{ getSlimeNom(slime) }}</h3>
            </div>
            <p><small> <em>
                Tous vos slimes sont visibles depuis l'espace connecté
            </em></small></p>
            <b-button @click="hideModal">Fermer</b-button>
        </b-col>
    </b-modal>
</template>

<script>
    import modalMixin                        from '@/mixin/modalMixin';
    import {getSlimeNom, requireSlimeImgSrc} from '@/util/slime';

    export default {
        name: "SlimeCollectModal",
        mixins: [modalMixin],
        props: {
            slime: {
                type: Object,
                required: true
            }
        },
        data: () => ({
            modalRef: 'slimeCollectModal'
        }),
        methods: {
            requireSlimeImgSrc,
            getSlimeNom,
            onHidden() {
                this.$store.dispatch('fireworks/stop').then(() => this.destroyModal());
            }
        },
        mounted() {
            this.$store.dispatch('fireworks/trigger');
        }
    }
</script>

<style scoped>
    .slime {
        margin-top: 20px;
        padding-top: 20px;
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-radius: 20px;
        border: 1px solid #eee;
    }

    .slime img {
        max-width: 128px;
    }
</style>